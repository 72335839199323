function Services(){
    return(
        <>
          <div className="col-xl-8">
            <div className="card content-box-card">
              <div className="card-body">
                <div className="top-info">
                  <div className="text">
                    <h1 className="main-title">
                      Services I <span>Offered</span>
                    </h1>
                    <p>
                      Transforming Ideas into Innovative Reality, Elevate Your
                      Vision with Our Expert{" "}
                      <b>Product Design and Development</b> Services!
                    </p>
                  </div>
                  <div className="available-btn">
                    <span>
                      <i className="fas fa-circle" /> Available For Hire
                    </span>
                  </div>
                </div>
                <div className="services">
                  <div className="row g-4">
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img src="assets/img/icons/ui-ux.svg" alt="ui-ux" />
                        </div>
                        <div className="text">
                          <h3 className="title">UI UX Design</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img src="assets/img/icons/app.svg" alt="app" />
                        </div>
                        <div className="text">
                          <h3 className="title">Mobile App</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img
                            src="assets/img/icons/prd-design.svg"
                            alt="prd-design"
                          />
                        </div>
                        <div className="text">
                          <h3 className="title">Product Design</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img
                            src="assets/img/icons/branding.svg"
                            alt="branding"
                          />
                        </div>
                        <div className="text">
                          <h3 className="title">Branding</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img src="assets/img/icons/camera.svg" alt="camera" />
                        </div>
                        <div className="text">
                          <h3 className="title">Photography</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img
                            src="assets/img/icons/youtube.svg"
                            alt="youtube"
                          />
                        </div>
                        <div className="text">
                          <h3 className="title">Motion Design</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img
                            src="assets/img/icons/web-development.svg"
                            alt="web-development"
                          />
                        </div>
                        <div className="text">
                          <h3 className="title">Web Development</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="services-item text-center">
                        <div className="image">
                          <img src="assets/img/icons/view.svg" alt="view" />
                        </div>
                        <div className="text">
                          <h3 className="title">Visualization</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-image">
                    <img
                      src="assets/img/blog/blog-img-1.jpg"
                      alt="blog-img-1"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div className="working-with-area">
                  <h2 className="main-common-title">
                    Working With 50+ Brands ✨ Worldwide
                  </h2>
                  <div className="working-with-main">
                    <div className="items">
                      <img src="assets/img/icons/notion.svg" alt="notion" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/webflow.svg" alt="webflow" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/mico.svg" alt="mico" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/framer.svg" alt="framer" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/zeplin.svg" alt="zeplin" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/figma.svg" alt="figma" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/notion.svg" alt="notion" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/webflow.svg" alt="webflow" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/mico.svg" alt="mico" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/framer.svg" alt="framer" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/zeplin.svg" alt="zeplin" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/figma.svg" alt="figma" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/notion.svg" alt="notion" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/webflow.svg" alt="webflow" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/mico.svg" alt="mico" />
                    </div>
                    <div className="items">
                      <img src="assets/img/icons/framer.svg" alt="framer" />
                    </div>
                  </div>
                </div>
                <div className="client-feedback">
                  <h2 className="main-common-title">
                    Trusted By 1200+ Clients
                  </h2>
                  <div className="row client-feedback-slider">
                    <div className="col-lg-6">
                      <div className="feedback-item">
                        <div className="feedback-top-info">
                          <div className="rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                          </div>
                          <div className="website">
                            <a href="#">
                              Framer.com
                              <svg
                                className="arrow-up"
                                width={14}
                                height={15}
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.91634 4.5835L4.08301 10.4168"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66699 4.5835H9.91699V9.8335"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div className="details">
                          <p>
                            "We've been using BentoFolio for over a year now,
                            and I must say, it's been a game-changer for us. The
                            user interface is intuitive and the feature.
                          </p>
                        </div>
                        <div className="designation">
                          <p>
                            <span>Oliver Clain</span> - Product Designer
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="feedback-item">
                        <div className="feedback-top-info">
                          <div className="rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                          </div>
                          <div className="website">
                            <a href="#">
                              Framer.com
                              <svg
                                className="arrow-up"
                                width={14}
                                height={15}
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.91634 4.5835L4.08301 10.4168"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66699 4.5835H9.91699V9.8335"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div className="details">
                          <p>
                            "We've been using BentoFolio for over a year now,
                            and I must say, it's been a game-changer for us. The
                            user interface is intuitive and the feature.
                          </p>
                        </div>
                        <div className="designation">
                          <p>
                            <span>Oliver Clain</span> - Product Designer
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="feedback-item">
                        <div className="feedback-top-info">
                          <div className="rating">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                          </div>
                          <div className="website">
                            <a href="#">
                              Framer.com
                              <svg
                                className="arrow-up"
                                width={14}
                                height={15}
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.91634 4.5835L4.08301 10.4168"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66699 4.5835H9.91699V9.8335"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div className="details">
                          <p>
                            "We've been using BentoFolio for over a year now,
                            and I must say, it's been a game-changer for us. The
                            user interface is intuitive and the feature.
                          </p>
                        </div>
                        <div className="designation">
                          <p>
                            <span>Oliver Clain</span> - Product Designer
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="awards-recognitions">
                  <h2 className="main-common-title">Awards and Recognitions</h2>
                  <div className="awards-recognitions-main">
                    <ul className="list-unstyled">
                      <li>
                        <a href="#" className="d-block w-100">
                          <div className="awards-item">
                            <div className="award-name">
                              <div className="icon">
                                <img
                                  src="assets/img/icons/adobe.svg"
                                  alt="adobe"
                                />
                              </div>
                              <div className="text">
                                <h4 className="title">Adobe Design Contest</h4>
                                <p className="year">2022 - 2023</p>
                              </div>
                            </div>
                            <div className="winner-tag">
                              <h4 className="title">
                                <svg
                                  className="icon"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.80234 12L3.40234 17.89L7.00034 17.657L8.59834 20.889L11.9983 15"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Runner Up
                              </h4>
                            </div>
                            <div className="project-btn">
                              <span>
                                View Project
                                <svg
                                  className="arrow-up"
                                  width={14}
                                  height={15}
                                  viewBox="0 0 14 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.91634 4.5835L4.08301 10.4168"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66699 4.5835H9.91699V9.8335"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="d-block w-100">
                          <div className="awards-item">
                            <div className="award-name">
                              <div className="icon">
                                <img
                                  src="assets/img/icons/dribbble.svg"
                                  alt="dribbble"
                                />
                              </div>
                              <div className="text">
                                <h4 className="title">
                                  Dribbble Design Contest
                                </h4>
                                <p className="year">2022 - 2023</p>
                              </div>
                            </div>
                            <div className="winner-tag">
                              <h4 className="title">
                                <svg
                                  className="icon"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.80234 12L3.40234 17.89L7.00034 17.657L8.59834 20.889L11.9983 15"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Gold Winner
                              </h4>
                            </div>
                            <div className="project-btn">
                              <span>
                                View Project
                                <svg
                                  className="arrow-up"
                                  width={14}
                                  height={15}
                                  viewBox="0 0 14 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.91634 4.5835L4.08301 10.4168"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66699 4.5835H9.91699V9.8335"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="d-block w-100">
                          <div className="awards-item">
                            <div className="award-name">
                              <div className="icon">
                                <img
                                  src="assets/img/icons/awwwards.png"
                                  alt="awwwards"
                                />
                              </div>
                              <div className="text">
                                <h4 className="title">Awwwards Nominee</h4>
                                <p className="year">2022 - 2023</p>
                              </div>
                            </div>
                            <div className="winner-tag">
                              <h4 className="title">
                                <svg
                                  className="icon"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.80234 12L3.40234 17.89L7.00034 17.657L8.59834 20.889L11.9983 15"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Runner Up
                              </h4>
                            </div>
                            <div className="project-btn">
                              <span>
                                View Project
                                <svg
                                  className="arrow-up"
                                  width={14}
                                  height={15}
                                  viewBox="0 0 14 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.91634 4.5835L4.08301 10.4168"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66699 4.5835H9.91699V9.8335"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="d-block w-100">
                          <div className="awards-item">
                            <div className="award-name">
                              <div className="icon">
                                <img
                                  src="assets/img/icons/behance.svg"
                                  alt="behance"
                                />
                              </div>
                              <div className="text">
                                <h4 className="title">
                                  Behance Design Contest
                                </h4>
                                <p className="year">2022 - 2023</p>
                              </div>
                            </div>
                            <div className="winner-tag">
                              <h4 className="title">
                                <svg
                                  className="icon"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 9C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3679 10.4087 15 12 15C13.5913 15 15.1174 14.3679 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 9C18 7.4087 17.3679 5.88258 16.2426 4.75736C15.1174 3.63214 13.5913 3 12 3C10.4087 3 8.88258 3.63214 7.75736 4.75736C6.63214 5.88258 6 7.4087 6 9Z"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 15L15.4 20.89L16.998 17.657L20.596 17.889L17.196 12"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.80234 12L3.40234 17.89L7.00034 17.657L8.59834 20.889L11.9983 15"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Gold Winner
                              </h4>
                            </div>
                            <div className="project-btn">
                              <span>
                                View Project
                                <svg
                                  className="arrow-up"
                                  width={14}
                                  height={15}
                                  viewBox="0 0 14 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.91634 4.5835L4.08301 10.4168"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66699 4.5835H9.91699V9.8335"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="frequently-asked-questions">
                  <h2 className="main-common-title">
                    Frequently Asked Questions
                  </h2>
                  <div className="frequently-asked-questions-main">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What does a product designer need to know?
                            <span className="ms-auto">
                              <span className="icon ms-4">
                                <img
                                  className="icon-plus"
                                  src="assets/img/icons/plus.svg"
                                  alt="plus"
                                />
                                <img
                                  className="icon-minus d-none"
                                  src="assets/img/icons/minus.svg"
                                  alt="minus"
                                />
                              </span>
                            </span>
                          </button>
                        </h4>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              I'm here to help if you're searching for a product
                              designer to bring your idea to life or a design
                              partner to help take your business to the next
                              level.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            What does a product designer need to know?
                            <span className="ms-auto">
                              <span className="icon ms-4">
                                <img
                                  className="icon-plus"
                                  src="assets/img/icons/plus.svg"
                                  alt="plus"
                                />
                                <img
                                  className="icon-minus d-none"
                                  src="assets/img/icons/minus.svg"
                                  alt="minus"
                                />
                              </span>
                            </span>
                          </button>
                        </h4>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              I'm here to help if you're searching for a product
                              designer to bring your idea to life or a design
                              partner to help take your business to the next
                              level.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            What does a product designer need to know?
                            <span className="ms-auto">
                              <span className="icon ms-4">
                                <img
                                  className="icon-plus"
                                  src="assets/img/icons/plus.svg"
                                  alt="plus"
                                />
                                <img
                                  className="icon-minus d-none"
                                  src="assets/img/icons/minus.svg"
                                  alt="minus"
                                />
                              </span>
                            </span>
                          </button>
                        </h4>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              I'm here to help if you're searching for a product
                              designer to bring your idea to life or a design
                              partner to help take your business to the next
                              level.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            What does a product designer need to know?
                            <span className="ms-auto">
                              <span className="icon ms-4">
                                <img
                                  className="icon-plus"
                                  src="assets/img/icons/plus.svg"
                                  alt="plus"
                                />
                                <img
                                  className="icon-minus d-none"
                                  src="assets/img/icons/minus.svg"
                                  alt="minus"
                                />
                              </span>
                            </span>
                          </button>
                        </h4>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              I'm here to help if you're searching for a product
                              designer to bring your idea to life or a design
                              partner to help take your business to the next
                              level.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            What does a product designer need to know?
                            <span className="ms-auto">
                              <span className="icon ms-4">
                                <img
                                  className="icon-plus"
                                  src="assets/img/icons/plus.svg"
                                  alt="plus"
                                />
                                <img
                                  className="icon-minus d-none"
                                  src="assets/img/icons/minus.svg"
                                  alt="minus"
                                />
                              </span>
                            </span>
                          </button>
                        </h4>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              I'm here to help if you're searching for a product
                              designer to bring your idea to life or a design
                              partner to help take your business to the next
                              level.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="work-together-slider">
                  <div className="slider-main d-flex gap-4 align-items-center">
                    <div className="slider-item">
                      <a href="contact.html">Let's 👋 Work Together</a>
                      <a href="contact.html">Let's 👋 Work Together</a>
                    </div>
                    <div className="slider-item">
                      <a href="contact.html">Let's 👋 Work Together</a>
                      <a href="contact.html">Let's 👋 Work Together</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </>
    )
}
export default Services;