import { NavLink } from "react-router-dom";

export default function Indexx() {
  return (
    <>
      <div className="col-xl-4">
        <div className="row g-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body work-experiance-card">
                <h3 className="card-title">Work Experience</h3>
                <div className="work-experiance-main">
                  <ul className="work-experiance-slider list-unstyled">
                    <li>
                      <div className="date">
                        <p>2017-2019</p>
                      </div>
                      <div className="info">
                        <div className="icon">
                          <img
                            src="assets/img/icons/photoshop.svg"
                            alt="adobe photoshop"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Graphics Aid</h4>
                          <h6 className="subtitle">
                            Senior Graphics Designer
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="date">
                        <p>2020-Now</p>
                      </div>
                      <div className="info">
                        <div className="icon">
                          <img
                            src="assets/img/icons/fiverr.svg"
                            alt="Fiverr"
                          />
                        </div>
                        <a href="https://www.fiverr.com/s/KPXolz" target="blank">
                          <div className="text">
                            <h4 className="title">Fiverr</h4>
                            <h6 className="subtitle">
                              Senior Photo Editor
                            </h6>
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="date">
                        <p>2021-Now</p>
                      </div>
                      <div className="info">
                        <div className="icon">
                          <img src="assets/img/icons/upwork.svg" alt="meta" />
                        </div>
                        <a href="https://www.upwork.com/freelancers/~01c8dc5fb4fd1bf426" target="_blank">
                          <div className="text">
                            <h4 className="title">UpWork</h4>
                            <h6 className="subtitle">Senior Photo Editor</h6>
                          </div>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card ">
              <div className="card-body">
                <h3 className="card-title">My Expert Area</h3>
                <div className="expertise-main mt-24">
                  <div className="row g-3">
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/laravel.svg"
                            alt="laravel"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Laravel-11</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/vue.svg"
                            alt="vue"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">VUE</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/react.svg"
                            alt="react"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">React</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/github.svg"
                            alt="github"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">GitHub</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/javascript.svg"
                            alt="javascript"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">JavaScript</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/jquery.svg"
                            alt="jquery"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">jQuery</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/php.svg"
                            alt="php"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">PHP</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/postman.svg"
                            alt="postman"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Postman</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/mysql.svg"
                            alt="mysql"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">MySQL</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/bootstrap.svg"
                            alt="bootstrap"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Bootstrap-5</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/html.svg"
                            alt="html"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Html-5</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/css.svg"
                            alt="css"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Css-3</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/photoshop.svg"
                            alt="photoshop"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Photoshop</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/figma.svg"
                            alt="figma"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Figma</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/canva.svg"
                            alt="canva"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Canva</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/powerpoint.svg"
                            alt="powerpoint"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">PowerPoint</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/access.svg"
                            alt="access"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Access</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/excel.svg"
                            alt="excel"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Excel</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6 col-6 nazad-card">
                      <div className="nazad-item">
                        <div className="image text-center">
                          <img
                            src="assets/img/icons/word.svg"
                            alt="word"
                          />
                        </div>
                        <div className="text">
                          <h4 className="title">Ms Word</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="card card-projects">
          <div className="card-body">
            <h3 className="card-title">
              Recent Projects{" "}
              <NavLink className="link-btn" to={"portfolioDetails"}>
                All Projects
                &nbsp;<i class="fas fa-arrow-right"></i>
              </NavLink>
            </h3>
            <div className="projects-main mt-24">
              <div className="row g-4 parent-container">
                <div className="col-lg-12">
                  <div className="project-item">
                    <div className="image">
                      <img
                        src="assets/img/images/pato.jpg"
                        alt="project-1"
                        className="img-fluid w-100"
                      />
                      <a
                        href="assets/img/images/pato.jpg"
                        className="gallery-popup full-image-preview parent-container"
                      >
                        <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M10 4.167v11.666M4.167 10h11.666" />
                        </svg>
                      </a>
                      <div className="info">
                        <span className="category">Full stack Hotel Hanagement Laravel</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="project-item">
                    <div className="image">
                      <img
                        src="assets/img/images/matrimony.jpg"
                        alt="project-2"
                        className="img-fluid w-100"
                      />
                      <a
                        href="assets/img/images/matrimony.jpg"
                        className="gallery-popup full-image-preview parent-container"
                      >
                        <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M10 4.167v11.666M4.167 10h11.666" />
                        </svg>
                      </a>
                      <div className="info">
                        <span className="category">Web Teplate (CMS) by React js</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="project-item">
                    <div className="image">
                      <img
                        src="assets/img/images/Gaements_Management.jpg"
                        alt="project-2"
                        className="img-fluid w-100"
                      />
                      <a
                        href="assets/img/images/Gaements_Management.jpg"
                        className="gallery-popup full-image-preview parent-container"
                      >
                        <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M10 4.167v11.666M4.167 10h11.666" />
                        </svg>
                      </a>
                      <div className="info">
                        <span className="category">Garments Managementy software by PHP</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="project-item">
                    <div className="image">
                      <img
                        src="assets/img/images/plugin.jpg"
                        alt="project-2"
                        className="img-fluid w-100"
                      />
                      <a
                        href="assets/img/images/plugin.jpg"
                        className="gallery-popup full-image-preview parent-container"
                      >
                        <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M10 4.167v11.666M4.167 10h11.666" />
                        </svg>
                      </a>
                      <div className="info">
                        <span className="category">WordPress Plugin Development</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}