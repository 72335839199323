export default function Blog() {
  return (
    <>
      <div className="col-xl-8">
        <div className="card content-box-card">
          <div className="card-body portfolio-card">
            <div className="top-info">
              <div className="text">
                <h1 className="main-title">
                  My Recent Article and Publications
                </h1>
                <p>
                  I'm here to help if you're searching for a product
                  designer to bring your idea to life or a design partner to
                  help take your business to the next level.
                </p>
              </div>
            </div>
            <div className="article-publications article-area">
              <div className="article-publications-main">
                <div className="row">
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-1.jpg"
                            alt="blog-img-1"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-2.jpg"
                            alt="blog-img-2"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-3.jpg"
                            alt="blog-img-3"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-4.jpg"
                            alt="blog-img-4"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-5.jpg"
                            alt="blog-img-5"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6">
                    <div className="article-publications-item">
                      <div className="image">
                        <a href="article" className="d-block w-100">
                          <img
                            src="assets/img/blog/blog-img-6.jpg"
                            alt="blog-img-6"
                            className="img-fluid w-100"
                          />
                        </a>
                        <a href="article" className="tags">
                          Development
                        </a>
                      </div>
                      <div className="text">
                        <a href="article" className="title">
                          Want To Upgrade Your Brain? Stop Doing These 7
                          Things
                        </a>
                        <ul className="list-unstyled">
                          <li>15 min read</li>
                          <li>Nov 6, 2023</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <ul className="list-unstyled">
                <li className="prev">
                  <button>
                    <svg
                      className="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                      ></path>
                    </svg>
                  </button>
                </li>
                <li>
                  <button>1</button>
                </li>
                <li>
                  <button>2</button>
                </li>
                <li>
                  <button>3</button>
                </li>
                <li>
                  <button className="next-page-btn">
                    <span className="dots">
                      <i className="fas fa-ellipsis-h" />
                    </span>
                    <span className="next-page">
                      <svg
                        className="icon icon-arrow-right"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m6 17 5-5-5-5" />
                        <path d="m13 17 5-5-5-5" />
                      </svg>
                    </span>
                    <span className="next-page-number">Next 4 pages</span>
                  </button>
                </li>
                <li>
                  <button>100</button>
                </li>
                <li className="next">
                  <button>
                    <svg
                      className="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      ></path>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}