export default function Comment() {
  return (
    <>


      <div className="col-xl-8">
        <div className="card content-box-card">
          <div className="card-body portfolio-card contact-card">
            <div className="top-info">
              <div className="text">
                <h1 className="main-title">
                  Let's 👋 <span>Work</span> Together
                </h1>
                <p>
                  I'm here to help if you're searching for a product
                  designer to bring your idea to life or a design partner to
                  help take your business to the next level.
                </p>
              </div>
            </div>
            <div className="contact-area">
              <div className="leave-comments-area">
                <div className="comments-box">
                  <form
                    id="contact-form"
                    action="https://marveltheme.com/tf/html/bentofolio-html/assets/mail.php"
                    method="POST"
                  >
                    <div className="row gx-3">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label">Name</label>
                          <input
                            name="name"
                            required=""
                            type="text"
                            className="form-control shadow-none"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label">Email</label>
                          <input
                            name="email"
                            required=""
                            type="email"
                            className="form-control shadow-none"
                            placeholder="Enter your email"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label">Your Occupation</label>
                          <input
                            name="subject"
                            required=""
                            type="text"
                            className="form-control shadow-none"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label">Type Of Comment</label>
                          <select
                            name="budget"
                            required=""
                            className="form-select shadow-none"
                          >
                            <option disabled="" selected="">
                              Select one...
                            </option>
                            <option value="Give Suggestion">Give Suggestion</option>
                            <option value="Want to Hire">
                              Want to Hire
                            </option>
                            <option value="Give Me Feedback">
                              Give Me Feedback
                            </option>
                            <option value="Want to Know About my Services">Want to Know About my Services</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-4">
                          <label className="form-label">Comment</label>
                          <textarea
                            name="message"
                            className="form-control shadow-none"
                            rows={4}
                            placeholder="Type details about your inquiry"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="submit-btn" type="submit">
                          <span>Send Your Comment</span>&nbsp;
                          <span><i class="fas fa-paper-plane"></i></span>
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="ajax-response mb-0" />
                </div>
              </div>
              <div className="contact-map-area">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.25280012016!2d-74.14448732737499!3d40.69763123331177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1711832776336!5m2!1sen!2sbd"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="frequently-asked-questions">
                <h2 className="main-common-title">
                  Frequently Asked Questions
                </h2>
                <div className="frequently-asked-questions-main">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What does a product designer need to know?
                          <span className="ms-auto">
                            <span className="icon ms-4">
                              <img
                                className="icon-plus"
                                src="assets/img/icons/plus.svg"
                                alt="plus"
                              />
                              <img
                                className="icon-minus d-none"
                                src="assets/img/icons/minus.svg"
                                alt="minus"
                              />
                            </span>
                          </span>
                        </button>
                      </h4>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            I'm here to help if you're searching for a
                            product designer to bring your idea to life or a
                            design partner to help take your business to the
                            next level.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What does a product designer need to know?
                          <span className="ms-auto">
                            <span className="icon ms-4">
                              <img
                                className="icon-plus"
                                src="assets/img/icons/plus.svg"
                                alt="plus"
                              />
                              <img
                                className="icon-minus d-none"
                                src="assets/img/icons/minus.svg"
                                alt="minus"
                              />
                            </span>
                          </span>
                        </button>
                      </h4>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            I'm here to help if you're searching for a
                            product designer to bring your idea to life or a
                            design partner to help take your business to the
                            next level.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What does a product designer need to know?
                          <span className="ms-auto">
                            <span className="icon ms-4">
                              <img
                                className="icon-plus"
                                src="assets/img/icons/plus.svg"
                                alt="plus"
                              />
                              <img
                                className="icon-minus d-none"
                                src="assets/img/icons/minus.svg"
                                alt="minus"
                              />
                            </span>
                          </span>
                        </button>
                      </h4>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            I'm here to help if you're searching for a
                            product designer to bring your idea to life or a
                            design partner to help take your business to the
                            next level.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What does a product designer need to know?
                          <span className="ms-auto">
                            <span className="icon ms-4">
                              <img
                                className="icon-plus"
                                src="assets/img/icons/plus.svg"
                                alt="plus"
                              />
                              <img
                                className="icon-minus d-none"
                                src="assets/img/icons/minus.svg"
                                alt="minus"
                              />
                            </span>
                          </span>
                        </button>
                      </h4>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            I'm here to help if you're searching for a
                            product designer to bring your idea to life or a
                            design partner to help take your business to the
                            next level.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What does a product designer need to know?
                          <span className="ms-auto">
                            <span className="icon ms-4">
                              <img
                                className="icon-plus"
                                src="assets/img/icons/plus.svg"
                                alt="plus"
                              />
                              <img
                                className="icon-minus d-none"
                                src="assets/img/icons/minus.svg"
                                alt="minus"
                              />
                            </span>
                          </span>
                        </button>
                      </h4>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            I'm here to help if you're searching for a
                            product designer to bring your idea to life or a
                            design partner to help take your business to the
                            next level.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}