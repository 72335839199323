function Sidebar(){
    return(
        <>
        <div style={{maxHeight:"900px"}} className="col-xl-4">
                  <div className="card profile-card">
                    <div className="card-body">
                      <div className="image text-center">
                        <img src="assets/img/images/nazad.png" alt="profile" />
                      </div>
                      <div className="text">
                        <h1 style={{color:"blue"}}>Abdullah Al Nazad</h1>
                        <h2 style={{textAlign:"center",color:"green" }} className="">Web Developer</h2>
                        <p>
                          A Passionate <span>Full Stack Developer</span> 🖥️ <br/>And a Photo Editor <i class="fas fa-images"></i>
                          <span>Student of ISDB-BISEW IT Scholarship Programme</span> Round-56 <span>Web Development By PHP Framework</span>{" "}
                        </p>
                        <a style={{marginLeft:"20px",fontWeight:"bold",color:"  #42b883",backgroundColor:"#35495e"}} className="btn">VUE.js-3</a>
                        <a style={{marginLeft:"5px",fontWeight:"bold"}} className="btn btn-danger">Laravel-11</a>
                        <a style={{marginLeft:"5px",color:"#61dafb",backgroundColor:"black",fontWeight:"bold"}} className="btn">React.js-19</a>
                        
                        <div className="common-button-groups">
                          <a className="btn btn-primary" href="tel:+8801580331370">
                           
                            <span><i style={{fontSize:"20px"}} class="fab fa-whatsapp"></i></span>&nbsp;
                            <span>+8801580331370</span>
                          </a>
                          <button
                            className="btn btn-copy"
                            data-clipboard-text="abdullahalnazad850@gmail.com"
                          >
                            <svg
                              className="icon"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Copy Email
                          </button>
                        </div>
                        <div className="nazad-button-groups">
                        <a className="btn btn-primary" href="assets/cv/Nazad_CV.pdf" download="Nazad CV.pdf">
                           <span style={{fontSize:"20px"}}>Click Here To Download CV</span>
                         </a>
                        </div>
                        <div className="social-media-icon">
                          <ul style={{textAlign:"center"}} className="list-unstyled">
                            <li>
                              <a href="https://bd.linkedin.com/in/abdullahalnahzad" target="_blank"> 
                                <i className="fab fa-linkedin" />
                              </a>
                            </li>
                            <li>
                              <a href="https://github.com/alnazad" target="_blank">
                                <i className="fab fa-github" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.fiverr.com/s/KPXolz" target="_blank">
                                <img height={"20px"} src="assets/img/icons/fiverr.svg" alt="fiverr"/>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.upwork.com/freelancers/~01c8dc5fb4fd1bf426" target="_blank">
                                <img height={"20px"} src="assets/img/icons/upwork.svg" alt="upwork"/>
                              </a>
                            </li>
                            <li>
                              <a href="https://twitter.com/abdullah_nazad" target="_blank">
                              <i className="fab fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/p/Cs0NfzevmaX/" target="_blank">
                                <i className="fab fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/people/Mohammad-Abdullah-Al-Nahzad/100087479240551/" target="_blank">
                                <i className="fab fa-facebook" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </>
    )
}
export default Sidebar;