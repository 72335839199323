import {BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Indexx from './pages/Indexx';
import About from './pages/About';
import Services from './pages/Services';
import Comment from './pages/Comment';
import HireMe from './pages/HireMe';
import Portfolio from './pages/Portfolio';
import PortfolioDetails from './pages/PortfolioDetails';
import Article from './pages/Article';
import Blog from './pages/Blog';
import Login from './pages/Login';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
  <Route path="/abdullahlogin" element={<Login/>}></Route>
    <Route path="/" element={<App/>}>
      <Route path="/" element={<Indexx/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/services" element={<Services/>}></Route>
      <Route path="/comment" element={<Comment/>}></Route>
      <Route path="/hire" element={<HireMe/>}></Route>
      <Route path="/portfolio" element={<Portfolio/>}></Route>
      <Route path="/portfolioDetails" element={<PortfolioDetails/>}></Route>
      <Route path="/article" element={<Article/>}></Route>
      <Route path="/blog" element={<Blog/>}></Route>
      
    </Route>
    
  </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
