import Header from "./main/Header";
import Footer from "./main/Footer";
import { Outlet,NavLink } from 'react-router-dom';
import Sidebar from "./main/Sidebar";
function Project() {
  return (
    <>
      <div id="page-content">
        {/* Navbar start */}
        <Header />

        {/* Navbar End */}
        {/* ------------------------------------------------------------------------------*/}
        {/* Carousel start */}
        {/* <SimpleSlider/> */}
        {/* Carousel end */}
        {/* ------------------------------------------------------------------------------*/}
        <main>

          {/* Body start */}
          <section className="home-area">
            <div className="container">
              <div className="row g-4">

                <Sidebar />
                <Outlet />
              </div>
            </div>
          </section>
          {/* Body end */}
          {/* background shape area start */}
          <div className="background-shapes">
            <div className="shape-1 common-shape">
              <img src="assets/img/bg/banner-shape-1.png" alt="banner-shape-1" />
            </div>
            <div className="shape-2 common-shape">
              <img src="assets/img/bg/banner-shape-1.png" alt="banner-shape-1" />
            </div>
            <div className="threed-shape-1 move-with-cursor" data-value={1}>
              <img src="assets/img/bg/object-3d-1.png" alt="object-3d-1" />
            </div>
            <div className="threed-shape-2 move-with-cursor" data-value={1}>
              <img src="assets/img/bg/object-3d-2.png" alt="object-3d-2" />
            </div>
          </div>
          {/* background shape area end */}
        </main>
        {/* ------------------------------------------------------------------------------*/}
        {/* Footer Start */}
        <Footer />
        {/* Footer end */}
      </div>
    </>
  );
}

export default Project;
