export default function Footer(){
  const monthYearString = new Date().toLocaleString('en-US', { month: 'long', year: 'numeric' });


    return(
        <>
        {/* footer part start */}
        <footer className="footer-area">
          <div className="container">
            <div className="text text-center">
              <p>
                Copy<i class="far fa-copyright"></i>Right March 2024 - {monthYearString}.All Rights Reserved. <a href="#"> devnazad.com</a> By <a href="#"> Mohammad Abdullah Al Nazad</a>
              </p>
            </div>
          </div>
        </footer>
        
        {/* footer part end */}
        </>
        
    )
    


}

