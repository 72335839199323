import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Login({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    

    // Fetch CSRF token from Laravel backend
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/login', {
                email,
                password,
            }, {
                withCredentials: true, // Ensure cookies are sent with the request
            });
            history.push('/dashboard');
        } catch (error) {
            setError('Invalid username or password');
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    };

    return (
        <section style={{ backgroundColor: "rgb(181 198 232)" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{ borderRadius: "1rem" }}>
                            <div className="card-body p-5 text-center">
                                <form onSubmit={handleSubmit}>
                                    <h3 className="mb-5">Sign in</h3>
                                    <div data-mdb-input-init="" className="form-outline mb-4">
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div data-mdb-input-init="" className="form-outline mb-4">
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            placeholder="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>{error && <p style={{color:"red"}}>{error}</p>}</div>
                                    {/* Checkbox */}
                                    <div className="form-check d-flex justify-content-start mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="form1Example3"
                                        />
                                        <label className="form-check-label" htmlFor="form1Example3">
                                            {" "}
                                            Remember password{" "}
                                        </label>
                                    </div>
                                    <button
                                        data-mdb-button-init=""
                                        data-mdb-ripple-init=""
                                        className="btn btn-primary btn-lg btn-block"
                                        type="submit"
                                    >
                                        Login
                                    </button>
                                </form>
                                <hr className="my-4" />
                                <button
                                    className="btn btn-lg btn-block btn-primary data-mdb-button-init "
                                    style={{ backgroundColor: "#dd4b39" }}
                                    type="submit"
                                >
                                    <i className="fab fa-google me-2" /> Sign in with google
                                </button>
                                <button
                                    className="btn btn-lg btn-block btn-primary mb-2 data-mdb-button-init "
                                    style={{ backgroundColor: "#3b5998", marginTop: "10px" }}
                                    type="submit"
                                >
                                    <i className="fab fa-facebook-f me-2" />
                                    Sign in with facebook
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
